import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Heading from "./Heading";
import { useState } from "react";

export default function ContactUs() {
  const [isChecked, setIsChecked] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    if (!event.target.checked) {
      setIsCheck(false);
    }
  };

  const handleBackButton = (event) => {
    event.preventDefault();
    navigate('/login'); // replace '/previous-url' with the desired URL
  };

  const handleCheckboxChange1 = (event) => {
    setIsCheck(event.target.checked);
  };
  return (
    <>
      <Heading />
      <div className="card m-3" >
        <div
          className="card-header bg-white text-black"
          style={{ fontSize: "25px" }}
        >
          <b>Contact Us</b>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-5 mb-3" style={{ fontSize: "20px" }}>
              <b>Query Type</b>
            </div>
            <div className="col-sm-7" style={{ fontSize: "20px" }}>
              <b>How to Contact?</b>
            </div>
            <hr />
            <div className="col-sm-5 mt-2" style={{ fontSize: "18px" }}>
              Login or First time Registration related issues or any technical
              issues.
            </div>
            <div className="col-sm-7 mt-2" style={{ fontSize: "18px" }}>
              If you have any queries, please refer to the{" "}
              <a
                href={`${process.env.PUBLIC_URL}/faq.docx`}
                download
                style={{ textDecoration: "none" }}
              >
                <b>FAQ.</b>
              </a>
              <br />
              <hr style={{ width: "800px", marginLeft: "0" }} />
              Does the problem still exist?
              <input
                className="ms-3"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <br />
              {isChecked && (
                <>
                  <a
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScLb6CD_rysIEpRyMFtdSD5xlDMxtnYYWWfXv3XXn-yTko8YQ/viewform?usp=sf_link"
                    style={{ textDecoration: "none" }}
                  >
                    <b>Click here to submit your query.</b>
                  </a>
                  <br />
                  <span className="text-danger">
                    <b>
                      Please make sure you enter below details while raising the
                      request
                    </b>
                  </span>
                  <ul>
                    <li>Registration number/ username  </li>
                    <li>Name of the candidate</li>
                    <li>
                      Details of the issue, along with relevant screen shots.{" "}
                    </li>
                  </ul>
                  <hr style={{ width: "800px" }} />
                  If your question hasn't been answered yet?
                  <input
                    className="ms-3"
                    type="checkbox"
                    checked={isCheck}
                    onChange={handleCheckboxChange1}
                  />
                  <br />
                  {isCheck && (
                    <>
                      Please feel free to call us at the numbers provided if
                      your question is still unanswered.
                      <br />
                      Phone - 18005728997 (on all working days from 10 AM to 5
                      PM)
                      <br />
                      Phone - 7428944699 (For technical issues on all working
                      days from 9 AM to 6 PM)
                      <br />
                      <br />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center" style={{marginBottom: 100}}>
        <button className="btn btn-success text-white" onClick={handleBackButton}>{"Back"}</button>
      </div>
    </>
  );
}
