import React, { useEffect, useState } from "react";
import logo from "src/assets/images/hpsc.png";
import { useRecoilState } from "recoil";
import { loadingState } from "src/atom/LoadingState";
import { fetchMetaData } from "src/services/MainPage/OnboardHomeService";

const Heading = () => {
  const [metaData, setMetaData] = useState(false);
  const [subDomain, setSubDomain] = useState("");
  const [loader, enableLoader] = useRecoilState(loadingState);

  const fetchMetaDataAPICall = async () => {
    enableLoader(true);
    const id = subDomain;
    try {
      var fetchInstructionsMethod = async () => {
        var response = await fetchMetaData(id);
        if (response?.success) {
          setMetaData(response.body);
          enableLoader(false);
        } else {
          enableLoader(false);
        }
      };
      enableLoader(false);
      fetchInstructionsMethod();
    } catch (error) {}
  };

  useEffect(() => {
    const avtValue = process.env.REACT_APP_AVT;
    setSubDomain(avtValue);
  }, []);

  useEffect(() => {
    if (subDomain) {
      fetchMetaDataAPICall();
    }
  }, [subDomain]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-center align-items-center">
            <img
              style={{ height: "70px", width: "auto", marginRight: "10px" }}
              src={logo}
              alt="Logo"
            />
            <div className="d-flex flex-column align-items-center">
              <span className="mt-2" style={{ fontWeight: "bold" }}>
                हरियाणा पब्लिक सर्विस कमीशन , पंचकुला
              </span>
              <span style={{ fontWeight: "bold" }}>
                HARYANA PUBLIC SERVICE COMMISSION, Panchkula
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8 col-lg-12">
            <div
              className="mt-2 text-center"
              style={{ fontWeight: "bold", fontSize: "25px" }}
            >
              {Array.isArray(metaData) &&
                metaData.map(
                  (data, index) =>
                    data.fieldKey === "headline" && (
                      <div key={index}>{data.fieldValue}</div>
                    )
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Heading;
